<template>
  <div>
    Super Secret!
  </div>
</template>

<script>
  export default {
    name: "FormAdmin"
  };
</script>

<style scoped lang="scss"></style>
